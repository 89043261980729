<template>
  <div class="user-delete-container">
    <div class="subheadline-large">
      {{ $t('user_delete.2fa_code_needed') }}
    </div>

    <div class="body-text-large mt-n-4xl">
      {{ $t('user_delete.2fa_code_needed_description') }}
    </div>

    <div class="w-full mt-s32">
      <CustomInput v-model="twofa" testId="twofa-input" type="number">
        {{ $t('user_delete.2fa_code') }}
      </CustomInput>
      <ButtonV2
        :inactive="isLoading"
        @onClick="sendEmailConfirmation"
        testId="btn-continue"
        class="mt-n-2xl"
        :label="$t('user_delete.support_modal.continue')"
        wide
      />
    </div>
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import DELETE_ACCOUNT_STEP_1 from '@/graphql/mutations/DeleteUserAccountStep1.gql';
import CustomInput from '@/components/misc/CustomInput/CustomInput.vue';

export default {
  name: 'DeleteUserContactSupport',
  components: {
    ButtonV2,
    CustomInput,
  },

  data() {
    return {
      isLoading: false,
      twofa: '',
    };
  },

  async mounted() {},

  methods: {
    async sendEmailConfirmation() {
      try {
        this.isLoading = true;
        await this.apolloApiCall({
          mutation: DELETE_ACCOUNT_STEP_1,
          variables: {
            twofa: this.twofa,
          },
        });
        this.showModal('DeleteAccountStepTwoEmailConfirmation');
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.user-delete-container {
  width: 400px;
  overflow: auto;
}

@media screen and (max-width: 1200px) {
  .user-delete-container {
    height: unset;
    width: unset;
  }
}
</style>
